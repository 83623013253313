import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import ChiroReview1 from "../images/chiro-review-1.png"
import ChiroReview2 from "../images/chiro-review-2.png"
import ChiroReview3 from "../images/chiro-review-3.png"
import ChiroReview4 from "../images/chiro-review-4.png"
import ChiroReview5 from "../images/chiro-review-5.jpg"
import ChiroReview6 from "../images/chiro-review-6.jpg"
import ChiroReview7 from "../images/chiro-review-7.png"
import ChiroReview8 from "../images/chiro-review-8.jpg"
import ChiroReview9 from "../images/chiro-review-9.jpg"
import ChiroReview10 from "../images/chiro-review-10.jpg"
import ChiroReview11 from "../images/chiro-review-11.png"
import ChiroReview12 from "../images/chiro-review-12.png"
import Hero from "../components/hero";
import SocialMedia from "../components/social-media";
import {Helmet} from "react-helmet";

import image1 from "../images/chirophysio/image1.png"
import image2 from "../images/chirophysio/image2.png"
import image3 from "../images/chirophysio/image3.jpg"
import image4 from "../images/chirophysio/image4.png"
import {useCollapse} from "react-collapsed";
import OpenGraphLogo from "../images/open-graph-logo.png"

function Collapsible(props) {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

    return (
        <div className={"py-2 border-t-2 border-accent/50"} {...getToggleProps()}>
            <h2 className={"text-lg font-bold p-1 flex justify-between items-center "} >
                {props.title}
                {isExpanded ?
                    <svg className={"fill-accent w-10 h-10 shrink-0"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>
                    :
                    <svg className={"fill-accent w-10 h-10 shrink-0"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                }

            </h2>
            <section  {...getCollapseProps()}>
                {props.children}
            </section>
        </div>
    )
}

function ChiropracticAndPhysicalTherapy(){
    return(
        <div>
            <Helmet>
                <title>Kinetic Impact | Chiropractic and Physical Therapy</title>
                <meta property="og:image" content={OpenGraphLogo}></meta>
            </Helmet>
            <Nav/>
            <Hero href={"https://kineticimpact.janeapp.com/#/1st-timers"} header={"Move without limitations and be physically active again WITHOUT pain meds or surgery"}>
                <div id={"content-start"} className={"flex flex-col justify center mx-auto px-4 lg:pt-8 pt-4 text-lg bg-white rounded-xl"}>
                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>You have lived with the frustration long enough. Not knowing if you can play that pickup game, go to that workout class or play with the kids without having to worry if your body is going to act up on you again.</p>
                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>The only solutions that seem to be given are pain medications or stop doing the things that you love doing that keep your stress in check. You have scrolled the vast depths of the internet for some answers and now you are more confused than when you started.</p>
                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>If you could do the things you love to do without having to worry about pain, would you be willing to put in the work to get it done? This is where our team comes in.</p>
                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Work Together!</button></a>
                    <h2 className={"lg:text-5xl text-4xl text-center pt-16 pb-10"}>What is the Process?</h2>
                    <div className={"flex flex-row lg:flex-nowrap items-center flex-wrap pb-8 max-w-5xl mx-auto"}>
                        <img className={"lg:w-1/5 w-full"} src={image3}/>
                        <div className={"lg:w-4/5 w-full flex flex-col lg:p-4"}>
                            <h3 className={"text-center text-3xl pb-4 pt-4"}>Free Discovery Call</h3>
                            <p>We want to hear your story, see if what you’re dealing with is something we have seen before, and is something that we can provide you long term relief with. The goal is to make sure you get the right care you need, the best clinician on our team for you, and it starts with getting to know you on a personal level. </p>
                        </div>
                    </div>
                    <div className={"flex flex-row lg:flex-nowrap items-center flex-wrap pb-8 max-w-5xl mx-auto"}>
                        <img className={"lg:w-1/5 w-full"} src={image4}/>
                        <div className={"lg:w-4/5 w-full flex flex-col lg:p-4"}>
                            <h3 className={"text-center text-3xl pb-4 pt-4"}>Share Your Story</h3>
                            <p>We meet so many people frustrated with the care they've been given simply because they have not been heard. It is our number 1 goal to make sure we hear your story and fully understand where you are currently at and where you want to go. This allows us to cater to YOU and help you remove limitations. Your strength is your health and we are here to help you become more resilient than you have ever been! </p>
                        </div>
                    </div>
                    <div className={"flex flex-row lg:flex-nowrap items-center flex-wrap pb-8 max-w-5xl mx-auto"}>
                        <img className={"lg:w-1/5 w-full"} src={image1}/>
                        <div className={"lg:w-4/5 w-full flex flex-col lg:p-4"}>
                            <h3 className={"text-center text-3xl pb-4 pt-4"}>Collaborative Assessment and Game-Plan</h3>
                            <p>We will explore movement together so we can gather enough information to put together a strategy both short and long term to reach your goals. This allows you to be in the driver’s seat for a change while we give you some direction from our experience and expertise. Your health requires you to play an active role and we are here to show you how to get the most out of your body. </p>
                        </div>
                    </div>
                    <div className={"flex flex-row lg:flex-nowrap items-center flex-wrap pb-8 max-w-5xl mx-auto"}>
                        <img className={"lg:w-1/5 w-full"} src={image2}/>
                        <div className={"lg:w-4/5 w-full flex flex-col lg:p-4"}>
                            <h3 className={"text-center text-3xl pb-4 pt-4"}>Execute the Plan with Support </h3>
                            <p>Change is hard and can be very overwhelming. This is why if and when you commit to working with us, we are committed to being in your corner - you become family. When you are challenging your body to become more resilient, there will be good and bad days. In our experience, navigating these ups and downs with an expert is a massive advantage. This is the level of care CEO’s and Pros get, so why shouldn’t you?</p>
                        </div>
                    </div>
                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Work Together!</button></a>
                    <h2 className={"lg:text-5xl text-4xl text-center  pt-16 pb-10"}>What you get when you join our family</h2>
                    <Collapsible title={"Education to get you back to exercising"}>
                        <div className={"flex flex-col justify-center"}>
                            <p className={"lg:pt-4 pb-4 max-w-5xl px-4 mx-auto"}>Does this sound like you… I don’t know why every time I try to exercise I keep getting hurt. I have literally looked up every exercise or stretch under the sun but NOTHING works! When I ask for help everyone tells me something different so now I have no idea what to believe. I know exercise is important but I don’t want to do anything to make it worse. </p>
                            <p className={"lg:pt-4 pb-4 max-w-5xl px-4 mx-auto"}>What if you had a team of professionals to teach you the KEYS to getting over the hump just like these people? Would you be willing to put on the blinders to become pain free?</p>
                        </div>
                    </Collapsible>
                    <Collapsible title={"Long Term RESULTS"}>
                        <div className={"flex flex-col justify-center"}>
                            <p className={"lg:pt-4 pb-4 max-w-5xl px-4 mx-auto"}>There are so many people that we see that have spent thousands of dollars on chiropractors, physical therapists, massage therapists and only seen temporary relief… and this KILLS us. We help you understand why you are experiencing your pain as well as strategies that provide long lasting results. </p>
                        </div>
                    </Collapsible>
                    <Collapsible title={"A clinician in your corner even between sessions"}>
                        <div className={"flex flex-col justify-center"}>
                            <p className={"lg:pt-4 pb-4 max-w-5xl px-4 mx-auto"}>The road to recovery can be incredibly overwhelming. Stop losing sleep because you are so overwhelmed by wondering if this will get better. We are here for you. We will be here for you every step of the way with guidance and support to make sure you are feeling stronger than you have ever felt before! You will have access via text or email with our clinicians so you have the support you need and deserve. </p>
                        </div>
                    </Collapsible>
                    <Collapsible title={"An actionable game plan with achievable goals"}>
                        <div className={"flex flex-col justify-center"}>
                            <p className={"lg:pt-4 pb-4 max-w-5xl px-4 mx-auto"}>Have you seen your doctor and they just gave you some pills and told you to stop doing everything? Though it may be with good intentions, this doesn’t help. We will work with you to put an actionable game plan together that will help you get back to doing things you love to do. </p>
                        </div>
                    </Collapsible>
                    <Collapsible title={"State of the Art Training Facility in 4S Ranch"}>
                        <div className={"flex flex-col justify-center"}>
                            <p className={"lg:pt-4 pb-4 max-w-5xl px-4 mx-auto"}>The future of healthcare will be teaching people how to train for the activities they love and this is why our office is located within a beautiful gym, Optimum Training and Performance. We will have access to anything you need to prepare your body for the activities you love to do. </p>
                        </div>
                    </Collapsible>
                    <Collapsible title={"Work With us in the Comfort of Your Own Gym Virtually"}>
                        <div className={"flex flex-col justify-center"}>
                            <p className={"lg:pt-4 pb-4 max-w-5xl px-4 mx-auto"}>We have been working with people all over the world for years. The approach we take is movement-based so we are able to guide you through things with a hands-off approach. We have helped hundreds of people get out of pain, avoid surgeries, and do it without actually ever seeing us in person.</p>
                        </div>
                    </Collapsible>
                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Work Together!</button></a>
<div className={"mb-4"}></div>
                    {/*<p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>What if we told you that you could wake up every morning feeling confident in how strong and resilient your body is? Would you believe us?</p>*/}
                {/*<p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>If you were given a plan that could put the days where you have to worry about whether today is going to be a “bad day” behind you, would you be willing to put in the work to get it done?</p>*/}
                {/*<p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>We are confident that we can help, but don’t just take it from us…</p>*/}
                {/*<img src={ChiroReview1} alt={'review'}/>*/}
                {/*<img src={ChiroReview2} alt={'review'}/>*/}
                {/*<a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Work Together!</button></a>*/}

                {/*<h2 className={"text-3xl text-center py-14"}>Nagging injuries putting life on hold?</h2>*/}
                {/*<p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>How would you feel if you had the SECRETS to overcoming the emotional rollercoaster of the small injuries that keep you from hitting your fitness goals? That frustration of not knowing if you can spend quality time with friends and family without having to worry if your body is going to act up on you again.</p>*/}
                {/*<img src={ChiroReview3} alt={'review'}/>*/}
                {/*<img src={ChiroReview4} alt={'review'}/>*/}
                {/*<a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Work Together!</button></a>*/}

                {/*<h2 className={"text-3xl text-center py-14"}>Is exercise hurting you?</h2>*/}
                {/*<p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>Does this sound like you… I don’t know why every time I try to exercise I keep getting hurt. I have literally looked up every exercise or stretch under the sun but NOTHING works! When I ask for help everyone tells me something different so now I have no idea what to believe. I know exercise is important but I don’t want to do anything to make it worse. </p>*/}
                {/*<p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>What if you had a team of professionals to teach you the KEYS to getting over the hump just like these people? Would you be willing to put on the blinders to become pain free?</p>*/}
                {/*<div className={"flex flex-row"}>*/}
                {/*    <img className={"w-1/2 lg:w-1/3 mx-auto"} src={ChiroReview5} alt={'review'}/>*/}
                {/*    <img className={"w-1/2 lg:w-1/3 mx-auto"} src={ChiroReview6} alt={'review'}/>*/}
                {/*</div>*/}
                {/*<a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Work Together!</button></a>*/}

                {/*<h2 className={"text-3xl text-center py-14"}>We provide you with more than just a “quick fix”!</h2>*/}
                {/*<p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>There are so many people that we see that have spent thousands of dollars on chiropractors, physical therapists, massage therapists and only seen temporary relief… and this KILLS us.*/}
                {/*    We want you to meet Cheryl, who we had the amazing opportunity to help on her journey. She had gone through the ringer of seeing so many specialists but was never able to get answers or long term relief. Here is how we helped her.*/}
                {/*</p>*/}
                {/*<img src={ChiroReview7} alt={'review'}/>*/}
                {/*<a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Work Together!</button></a>*/}

                {/*<h2 className={"text-3xl text-center py-14"}>The road to recovery can be OVERWHELMING.</h2>*/}
                {/*<p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>Stop losing sleep because you are so overwhelmed by wondering if this will get better. We are here for you.</p>*/}
                {/*<p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>We will be here for you every step of the way with guidance and support to make sure you are feeling stronger than you have ever felt before!</p>*/}
                {/*<div className={"flex flex-row"}>*/}
                {/*    <img className={"w-1/2 lg:w-1/3 mx-auto"} src={ChiroReview8} alt={'review'}/>*/}
                {/*    <img className={"w-1/2 lg:w-1/3 mx-auto"} src={ChiroReview9} alt={'review'}/>*/}
                {/*</div>*/}

                {/*<h2 className={"text-3xl text-center py-14"}>We are not your average clinic.*/}
                {/*</h2>*/}
                {/*<p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>Have you seen your doctor and they just gave you some pills and told you to stop doing everything? Do you feel like your doctor is not listening to you and you are receiving cookie cutter care that does not take into account who you are and what you want to do? If so, we are not surprised and we are here to help!</p>*/}
                {/*<img className={"w-1/2 lg:w-1/3 mx-auto"} src={ChiroReview10} alt={'review'}/>*/}
                {/*<img src={ChiroReview11} alt={'review'}/>*/}
                {/*<img src={ChiroReview12} alt={'review'}/>*/}
                {/*<a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Let's Work Together!</button></a>*/}

                </div>
            </Hero>



            <Footer/>
        </div>
    )
}

export default ChiropracticAndPhysicalTherapy